<!--
    Props:
        - item: Deverá trazer o nome, valorObtido (o resultado do usuário) e valorMaximo (O valor máximo que aquela atividade valia)
-->

<template>
  <v-card elevation="0" class="d-flex flex-column mx-2 pa-2">
    <p class="font-weight-bold subtitle-2 pa-0 mb-1">{{ item.nome }}</p>

    <div class="d-flex flex-row align-center justify-space-between">
      <v-progress-linear
        :color="item.colorProgress ? item.colorProgress : '#56ca00'"
        :value="valorCalculado"
        height="8"
        rounded
        class="mr-4"
      ></v-progress-linear>

      <h4 class="font-weight-bold text-no-wrap">
        {{ item.valorObtido }} / {{ item.valorMaximo }}
      </h4>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "ProgressoLinearComponent",
  props: {
    item: {
      default: {
        nome: "",
        valorObtido: 0,
        valorMaximo: 0,
      },
    },
    elevation: {
      default: 0,
    },
    colorProgress: {
      default: "",
    },
  },

  computed: {
    valorCalculado() {
      let value = (this.item.valorObtido * 100) / this.item.valorMaximo;

      return value;
    },
  },
};
</script>

<style></style>
