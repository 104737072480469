<template>
  <div elevation="0" class="pa-4 d-flex flex-column">
    <v-tabs show-arrows class="border-bottom" v-model="tabs">
      <v-tabs-slider color="#162C40"></v-tabs-slider>
      <v-tab
        v-for="tab in items"
        :key="tab.id"
        :href="`#tab-${tab.id}`"
        class="primary--text"
      >
        <span class="color-legiio">{{ tab.nome }}</span>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tabs">
      <v-tab-item
        v-for="tabItem in items"
        :key="tabItem.id + 1"
        :value="'tab-' + tabItem.id"
      >
        <v-row class="height-max-limit-3 overflow-y-auto" no-gutters>
          <v-col
            sm="12"
            md="6"
            cols="6"
            v-for="item in tabItem.perguntas"
            :key="item.id"
          >
            <div class="d-flex align-center justify-center ma-2">
              <ProgressLinearComponent :item="item" />
            </div>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
export default {
  name: "TabsCompetenciaComponent",
  props: ["items"],

  data() {
    return {
      tabs: null,
    };
  },
};
</script>

<style scoped>
.height-max-limit-3 {
  max-height: 44em;
}
</style>
