<!--

    Props:
        - items:
            - competencias: Deverá trazer o nome, valorObtido (o resultado do usuário) e valorMaximo (O valor máximo que aquela atividade valia);
            - grafico_categorias: Deverá trazer um array de strings com as categorias;
            - grafico_series: Deverá ter um array de objeto que deverá conter 2 propriedades:
                - nome: String;
                - data: Array (deverá ser uma array de números)

-->

<template>
  <v-card class="pa-4" elevation="0">

    <h4 class="my-4">Resultado Geral</h4>

    <v-card max-height="300" elevation="0" class="overflow-y-auto pa-1">
      <ProgressLinearComponent
        v-for="item in items.competencias"
        :key="item.id"
        :item="item"
      />
    </v-card>

    <div class="ma-2 pa-2 white border-5 d-flex align-center justify-center">
      <VueApexCharts
        type="radar"
        height="350"
        width="250"
        :options="chartOptions"
        :series="series"
      ></VueApexCharts>
    </div>
  </v-card>
</template>

<script>
import ProgressLinearComponent from "./ProgressLinearComponent.vue";
import VueApexCharts from "vue-apexcharts";

export default {
  name: "BarraLateralComponent.vue",
  components: {
    ProgressLinearComponent,
    VueApexCharts,
  },
  props: {
    items: {
      default: {
        competencias: [
          {
            id: 0,
            nome: "",
            valorObtido: 0,
            valorMaximo: 0,
          },
        ],
        grafico_categorias: ["", "", "", "", ""],
        grafico_series: [
          {
            name: "",
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
        ],
      },
    },
  },

  created() {
    this.chartOptions.xaxis.categories = this.items.grafico_categorias;
    this.series = this.items.grafico_series;
  },

  updated() {
    this.chartOptions.xaxis.categories = this.items.grafico_categorias;
    this.series = this.items.grafico_series;
  },

  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          height: 300,
          type: "radar",
          dropShadow: {
            enabled: true,
            blur: 1,
            left: 1,
            top: 1,
          },
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          radar: {
            size: 90,
            polygons: {
              strokeColors: "#e9e9e9",
              fill: {
                colors: ["#f8f8f8", "#fff"],
              },
            },
          },
        },
        markers: {
          size: 0,
        },
        stroke: {
          width: 2,
        },
        fill: {
          opacity: 0.1,
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          tickAmount: 7,
          labels: {
            formatter: function (val, i) {
              if (i % 2 === 0) {
                return val;
              } else {
                return "";
              }
            },
          },
        },
        responsive: [
          {
            breakpoint: 1100,
            options: {
              plotOptions: {
                radar: {
                  size: 80,
                },
              },
            },
          },
          {
            breakpoint: 939,
            options: {
              plotOptions: {
                radar: {
                  size: 70,
                },
              },
            },
          },
        ],
      },
    };
  },
};
</script>

<style scoped>
.border-5 {
  border-radius: 5px;
}
</style>
