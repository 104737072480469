<template>
  <div class="d-flex flex-column flex-md-row justify-space-between">
    <div class="ma-2 w-30-custom">
      <BarraLateralComponent :items="items.autoavaliacao.geral" />
    </div>

    <div class="ma-2 w-full-custom">
      <v-card elevation="0" class="pa-4 d-flex flex-column">
        <TabsCompetenciaComponent :items="items.autoavaliacao.competencia" />
      </v-card>
    </div>
  </div>
</template>

<script>
import BarraLateralComponent from "./components/BarraLateralComponent.vue";
import ProgressLinearComponent from "./components/ProgressLinearComponent.vue";
import TabsCompetenciaComponent from "./components/TabsCompetenciaComponent.vue";

export default {
  name: "Autoavaliacao",
  components: {
    BarraLateralComponent,
    ProgressLinearComponent,
    TabsCompetenciaComponent,
  },

  computed: {
    items: {
      get() {
        return this.$store.getters[
          "avd/getVisualizarAvaliacao"
        ];
      },
    },
  },
};
</script>

<style scoped>
.icon-person {
  font-size: 60pt;
  color: yellow;
}

.font-size-person {
  font-size: 27pt;
}

.w-full-custom {
  width: 100%;
}

.w-30-custom {
  width: 30%;
}

@media (max-width: 959px) {
  .w-30-custom {
    width: 100%;
  }
}
</style>
