import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4 d-flex flex-column",attrs:{"elevation":"0"}},[_c(VTabs,{staticClass:"border-bottom",attrs:{"show-arrows":""},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c(VTabsSlider,{attrs:{"color":"#162C40"}}),_vm._l((_vm.items),function(tab){return _c(VTab,{key:tab.id,staticClass:"primary--text",attrs:{"href":("#tab-" + (tab.id))}},[_c('span',{staticClass:"color-legiio"},[_vm._v(_vm._s(tab.nome))])])})],2),_c(VTabsItems,{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},_vm._l((_vm.items),function(tabItem){return _c(VTabItem,{key:tabItem.id + 1,attrs:{"value":'tab-' + tabItem.id}},[_c(VRow,{staticClass:"height-max-limit-3 overflow-y-auto",attrs:{"no-gutters":""}},_vm._l((tabItem.perguntas),function(item){return _c(VCol,{key:item.id,attrs:{"sm":"12","md":"6","cols":"6"}},[_c('div',{staticClass:"d-flex align-center justify-center ma-2"},[_c('ProgressLinearComponent',{attrs:{"item":item}})],1)])}),1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }